import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import $ from "jquery"
import _ from "lodash"
import { navigate } from "@reach/router"
import GenerateLink from "../../common/site/generate-link"
import TrustPilotWidget from "../../TrustPilotWidget"
import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
import PartnerLogo1 from "../../../images/partner/arun-estates.svg"
import PartnerLogo2 from "../../../images/partner/logo-svg.svg"
import PartnerLogo3 from "../../../images/partner/ArunBrandBook.svg"
import PartnerLogo4 from "../../../images/partner/douglasallen-site-logo-white.svg"
import PartnerLogo5 from "../../../images/partner/pittis.svg"
// import PartnerLogo6 from "../../../images/partner/MMD-1-white.svg"
import PartnerLogo6 from "../../../images/partner/mm-logo-stacked-all white.svg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { isMobile, isDesktop } from "react-device-detect";

// styles
import "./Banner.scss"
// markup

const HomeBanner = (props) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      banners(publicationState: LIVE) {
          id
          Name
          Image {
            url
            alternativeText
          }
          imagetransforms
      }

  }
  }
`);

  const areaguides = data.glstrapi.banners;

  const [areaimages, setAreaImages] = useState('');
  const [processedImages, setProcessedImages] = useState(JSON.stringify({}));

  useEffect(() => {
    if (areaguides.length > 0) {
      var img_main = _.shuffle(areaguides)[0]
      setAreaImages(img_main)

      // let processedImages = JSON.stringify({});
      if (img_main.imagetransforms?.Image_Transforms) {
        setProcessedImages(img_main.imagetransforms.Image_Transforms);
      }
    }
  }, []);

  let banner_width = "770"
  let banner_height = "700"
  if (isMobile) {
    banner_width = "376"
    banner_height = "600"
  }
  if (isDesktop) {
    banner_width = "1800"
    banner_height = "1055"
  }

  return (
    <React.Fragment>
      <div className="main-banner components--Home--Banner-Banner">
        <div className="main-banner-embed">
          {/* <video width="100%" height="100%" autoPlay playsInline loop muted className="video-banner">
            <source src={BannerVideo} type="video/mp4" />
          </video> */}
            {areaimages &&
              <ImageTransform
                imagesources={areaimages.Image.url}
                renderer="srcSet"
                imagename="banners.Image.details"
                attr={{ alt: '', class: '', width:`${banner_width}`, height:`${banner_height}` }}
                imagetransformresult={processedImages}
                id={areaimages.id}
              />
            }
          {/* {areaimages[0] &&
            <GatsbyImage image={getImage(areaimages[0].Image.url_sharp)} alt={areaimages[0].Image.alternativeText} />
          } */}
        </div>
        <div className="main-banner-content">
          <Container>
            <InView {...inViewOptions}>
              {({ ref, inView }) => (
                <motion.div
                  className="banner-text"
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={contentItemStagger}

                >

                  <motion.div key={1} custom={1} variants={fadeInFromTop}>
                    {parse(props.Banner_Content)}
                  </motion.div>

                  <div className="btn-wrap">
                    {props.Banner_CTA_1_Link && props.Banner_CTA_1_Label &&

                      <motion.div key={2} custom={2} variants={fadeInFromTop} className="btn-width-cta">
                        <GenerateLink link={props.Banner_CTA_1_Link} class="btn btn-secondary">
                          {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                          {props.Banner_CTA_1_Label}
                        </GenerateLink>
                      </motion.div>
                    }
                    {props.Banner_CTA_2_Link && props.Banner_CTA_2_Label &&

                      <motion.div key={2} custom={2} variants={fadeInFromTop} className="btn-width-cta">
                        <GenerateLink link={props.Banner_CTA_2_Link} class="btn">
                          {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                          {props.Banner_CTA_2_Label}
                        </GenerateLink>
                      </motion.div>
                    }
                  </div>

                  {props.Show_Reviews &&
                    <div className="rating-block">
                      <motion.div variants={contentItem} className="trustpilot-review">
                        <TrustPilotWidget />
                      </motion.div>

                      <motion.div variants={contentItem} className="google-review">

                        <div className="review-logo">
                          <img src={GoogleReviews} alt="" width="74" />
                        </div>
                        <p>
                          <strong>4.9 out of 5</strong>
                          based on 307 reviews
                        </p>
                      </motion.div>
                    </div>
                  }

                  <div className="parter-logos">
                    <p className="content-text">The Arun Estates family</p>
                    <ul className="partner-logo d-lg-flex d-none">
                      <li className="partner-logo1">
                        {/* <motion.div variants={contentItem}> */}
                          <img src={PartnerLogo1} alt="" />
                        {/* </motion.div> */}
                      </li>
                      <li className="partner-logo3">
                        {/* <motion.div variants={contentItem}> */}
                        <a href="https://www.cubittandwest.co.uk/" target="_blank"><img src={PartnerLogo3} alt="" /></a>
                        {/* </motion.div> */}
                      </li>
                      <li className="partner-logo4">
                        {/* <motion.div variants={contentItem}> */}
                        <a href="https://www.douglasallen.co.uk/" target="_blank"><img src={PartnerLogo4} alt="" /></a>
                        {/* </motion.div> */}
                      </li>
                      <li className="partner-logo5">
                        {/* <motion.div variants={contentItem}> */}
                        <a href="https://www.pittis.co.uk/" target="_blank"><img src={PartnerLogo5} alt="" /></a>
                        {/* </motion.div> */}
                      </li>
                      <li className="partner-logo2">
                        {/* <motion.div variants={contentItem}> */}
                        <a href="https://www.wardsofkent.co.uk/" target="_blank"><img src={PartnerLogo2} alt="" /></a>
                        {/* </motion.div> */}
                      </li>
                      <li className="partner-logo6">
                        {/* <motion.div variants={contentItem}> */}
                        <a href="https://mortgagemattersdirect.co.uk/" target="_blank"><img src={PartnerLogo6} alt="" /></a>
                        {/* </motion.div> */}
                      </li>
                    </ul>

                    <div className="d-lg-none d-block">
                      <ul className="partner-logo mobile d-flex">
                        <li className="partner-logo1 me-0 pad"><a href="https://www.cubittandwest.co.uk/" target="_blank"><img src={PartnerLogo1} alt="" /></a></li>
                        <li className="partner-logo3 me-0 pad"><img src={PartnerLogo3} alt="" /></li>
                        <li className="partner-logo4 me-0"><img src={PartnerLogo4} alt="" /></li>
                      </ul>
                      <ul className="partner-logo mobile d-flex">
                        <li className="partner-logo5 me-0 pad"><img src={PartnerLogo5} alt="" /></li>
                        <li className="partner-logo2 me-0 pad"><img src={PartnerLogo2} alt="" /></li>
                        <li className="partner-logo6 me-0 pad"><img src={PartnerLogo6} alt="" /></li>
                      </ul>
                    </div>
                  </div>




                </motion.div>
              )}
            </InView>





          </Container>
        </div>


      </div>
    </React.Fragment>
  )
}

export default HomeBanner